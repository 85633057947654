import React              from 'react';
import { useRecoilValue } from 'recoil';
import { characterState } from '../atoms';
import Character          from './Character';

function CharacterLoad() {
  const character: any = useRecoilValue(characterState);
  return (
    <Character item={character}/>
  );
}

export default CharacterLoad;
