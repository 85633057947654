import React                        from 'react';
import { useRecoilState }           from 'recoil';
import { currentSatisfactionState } from '../atoms';
import { ReactComponent as Emoji0 } from '../assets/emoji_0.svg';
import { ReactComponent as Emoji1 } from '../assets/emoji_1.svg';
import { ReactComponent as Emoji2 } from '../assets/emoji_2.svg';
import { ReactComponent as Emoji3 } from '../assets/emoji_3.svg';
import { ReactComponent as Emoji4 } from '../assets/emoji_4.svg';
import { ReactComponent as Emoji5 } from '../assets/emoji_5.svg';
import Debug                        from './Debug';
import HeaderItem                   from './HeaderItem';

function Satisfaction() {
  const [satisfaction] = useRecoilState(currentSatisfactionState);
  let SatisfactionLogo: any;
  switch (satisfaction) {
    case 0:
      SatisfactionLogo = Emoji0;
      break;
    case 1:
    case 2:
      SatisfactionLogo = Emoji1;
      break;
    case 3:
    case 4:
      SatisfactionLogo = Emoji2;
      break;
    case 5:
    case 6:
      SatisfactionLogo = Emoji3;
      break;
    case 7:
    case 8:
      SatisfactionLogo = Emoji4;
      break;
    case 9:
    case 10:
      SatisfactionLogo = Emoji5;
      break;
  }
  return (
    <HeaderItem>
      <div className="satisfaction">
        {SatisfactionLogo ? <SatisfactionLogo height="100%" width="100%"/> : ''}
        <Debug text={`${satisfaction}`}/>
      </div>
    </HeaderItem>
  );
}

export default Satisfaction;
