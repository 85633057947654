import React                         from 'react';
import { useRecoilState }            from 'recoil';
import { settingsDebugModeState }    from '../atoms';

function Debug({text}: {text: string}) {
  const [debugMode] = useRecoilState(settingsDebugModeState);
  return debugMode ? (
    <div className="debug">
      {text}
    </div>
  ): <></>;
}

export default Debug;
