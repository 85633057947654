import React                from 'react';
import { getHighScore }     from '../store';
import { numberWithCommas } from '../utils';

function HighScore() {
  return (
    <div className="high-score">
      <div>Լավագույն Արդյունք:</div>
      <div>${numberWithCommas(getHighScore() * 1000000)}</div>
    </div>
  );
}

export default HighScore;
