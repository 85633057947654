import React                                          from 'react';
import { useRecoilState }                             from 'recoil';
import { addedValuationState, currentValuationState } from '../atoms';
import { ReactComponent as Money }                    from '../assets/money.svg';
import HeaderItem                                     from './HeaderItem';
import { numberWithCommas }                           from '../utils';

function Valuation() {
  const [currentValuation] = useRecoilState(currentValuationState);
  const [addedValuation] = useRecoilState(addedValuationState);
  const current = numberWithCommas(currentValuation * 1000000);
  let added = numberWithCommas((Math.abs(addedValuation) || 0) * 1000000);
  let details = '';
  if (addedValuation >= 0) {
    details = 'added';
    added = `+$${added}`;
  } else {
    added = `-$${added}`;
    details = 'subtracted';
  }
  return (
    <HeaderItem>
      <div className="valuation">
        <div className="valuation-top">
          <Money height="3.5em" width="3.5em"/>
          <div className="valuation-added" aria-details={details}>{addedValuation ? added : ''}</div>
        </div>
        <div className="valuation-bottom">${current}</div>
      </div>
    </HeaderItem>
  );
}

export default Valuation;
