import React         from 'react';
import Question      from './Question';
import Answers       from './Answers';
import CharacterLoad from './CharacterLoad';
import Character     from './Character';

function Main() {
  return (
    <div className="main">
      <React.Suspense fallback={<Character/>}>
        <CharacterLoad/>
      </React.Suspense>
      <Question/>
      <Answers/>
    </div>
  );
}

export default Main;
