import { getQuarterQuestionsByPeriod } from './api';
import { getRandomInt, wait }          from './utils';

export const store: any = {
  questions: [],
  answers: [],
};

export async function initQuestions(quarter: number, period: number) {
  store.questions = await getQuarterQuestionsByPeriod(quarter, period);
}

type GetNextQuestionOptions = {
  quarter: number;
  period: number;
  minQuestionsPerPeriod: number;
  maxQuestionsPerPeriod: number;
}

function getQuestionWithCheck(): any {
  let question: any = store.questions.shift();
  if (question?.dependingAnswer) {
    const answer = store.answers.find((a: any) => a.id === question.dependingAnswer.id);
    if (answer) {
      return question;
    }
    return getQuestionWithCheck();
  }
  return question;
}

export async function getNextQuestions(options: GetNextQuestionOptions) {
  try {
    let {quarter, period, minQuestionsPerPeriod, maxQuestionsPerPeriod} = options;
    let question = getQuestionWithCheck();
    if (!question) {
      period++;
      const take = getRandomInt(minQuestionsPerPeriod, maxQuestionsPerPeriod);
      store.questions = await getQuarterQuestionsByPeriod(quarter, period, {
        take,
      });
      if (!store.questions.length) {
        quarter = options.quarter + 1;
        period = 1;
        store.questions = await getQuarterQuestionsByPeriod(quarter, period, {
          take,
        });
      }
      question = getQuestionWithCheck();
    } else {
      await wait(0.5);
    }
    return {
      quarter,
      period,
      question,
    }
  } catch (e) {
    console.error('getNextQuestions failed', e);
    return null;
  }
}

export function setHighScore(score: number) {
  const current = getHighScore();
  if (score > current) {
    localStorage.setItem('high_score', String(score));
  }
}

export function getHighScore() {
  return Number(localStorage.getItem('high_score')) || 0;
}
