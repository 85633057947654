import React        from 'react';
import Valuation    from './Valuation';
import Satisfaction from './Satisfaction';
import Quarter      from './Quarter';

function Header() {
  return (
    <div className="header">
      <Valuation/>
      <Satisfaction/>
      <Quarter/>
    </div>
  );
}

export default Header;
