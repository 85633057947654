import React from 'react';
import Sound from './Sound';
import Info  from './Info';

function BottomButtons() {
  return (
    <div className="bottom-buttons">
      <Sound/>
      <Info/>
    </div>
  );
}

export default BottomButtons;
