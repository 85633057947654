import { atom, RecoilState, selector } from 'recoil';
import { Routes }                      from './enums/Routes';
import { getCharacter }                from './api';
import { Volume }                      from './enums/Volume';

export const settingsMinQuestionsPerPeriodState = atom({
  key: 'settingsMinQuestionsPerPeriod',
  default: 5,
});
export const settingsMaxQuestionsPerPeriodState = atom({
  key: 'settingsMaxQuestionsPerPeriod',
  default: 5,
});
export const settingsDebugModeState = atom({
  key: 'settingsDebugMode',
  default: false,
});
export const quarterState = atom({
  key: 'quarterState',
  default: 0,
});
export const periodState = atom({
  key: 'periodState',
  default: 0,
});
export const currentQuestionState: RecoilState<any> = atom({
  key: 'currentQuestionState',
  default: null,
});
export const currentValuationState = atom({
  key: 'currentValuationState',
  default: 0,
});
export const currentSatisfactionState = atom({
  key: 'currentSatisfactionState',
  default: 0,
});
export const loadingState = atom({
  key: 'loadingState',
  default: false,
});
export const addedValuationState = atom({
  key: 'addedValuationState',
  default: 0,
});
export const routeState = atom({
  key: 'routeState',
  default: Routes.Welcome,
});
export const gameOverState: RecoilState<any> = atom({
  key: 'gameOverState',
  default: null,
});
export const volumeState = atom({
  key: 'volumeState',
  default: Volume.OnlySounds,
});
export const openInfoState = atom({
  key: 'openInfoState',
  default: false,
});
export const startedState = atom({
  key: 'startedState',
  default: false,
});
export const characterIdState = selector({
  key: 'characterIdState',
  get: ({get}) => {
    const question = get(currentQuestionState);
    return question?.character.id;
  },
});
export const cachedCharacterState = selector({
  key: 'cachedCharacterState',
  get: ({get}) => {
    const question = get(currentQuestionState);
    return question?.character;
  },
});
export const characterState = selector({
  key: 'characterState',
  get: async ({get}) => {
    const questionId = get(characterIdState);
    return getCharacter(questionId);
  },
});


