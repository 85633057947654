import React, { useContext } from 'react';
import { useRecoilState }    from 'recoil';
import { volumeState }       from '../atoms';
import { SoundItem }         from '../enums/SoundItem';
import { SoundsContext }     from '../SoundsContext';

function ShareButton() {
  const sounds = useContext(SoundsContext);
  const [volume] = useRecoilState(volumeState);
  return (
    <div className="share-button" onClick={() => {
      sounds.play(SoundItem.Click, volume);
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.origin}`)
    }}>
      Տարածել
    </div>
  );
}

export default ShareButton;
