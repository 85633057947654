import React, { Fragment } from 'react';
import { Routes }          from '../enums/Routes';
import Welcome             from '../views/Welcome';
import GameOver            from '../views/GameOver';
import Mobile              from '../views/Mobile';

function Route(props: any) {
  let View: any = Fragment;
  switch (props.route) {
    case Routes.Welcome:
      View = Welcome;
      break;
    case Routes.GameOver:
      View = GameOver;
      break;
    default:
      View = Mobile;
  }
  return (
    <View/>
  );
}

export default Route;
