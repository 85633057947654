import React                    from 'react';
import { useRecoilValue }       from 'recoil';
import { cachedCharacterState } from '../atoms';

function Character({item}: any) {
  const cachedCharacter = useRecoilValue(cachedCharacterState);
  const character = item || cachedCharacter || {};
  return (
    <div className="character">
      <div className="character-img">
        <img
          src={character?.avatar}
          alt=""
          height="100%"
          width="100%"
        />
      </div>
      <div className="character-right">
        <div className="loader"/>
        <div className="character-details">
          <div className="character-name">
            {character?.name}
          </div>
          <div className="character-job-title">
            {character?.jobTitle}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Character;
