import React from 'react';

function HeaderItem(props: any) {
  return (
    <div className="header-item">
      {props.children}
    </div>
  );
}

export default HeaderItem;
