import React                    from 'react';
import { useRecoilState }       from 'recoil';
import { currentQuestionState } from '../atoms';
import Debug                    from './Debug';

function Question() {
  const [currentQuestion] = useRecoilState(currentQuestionState);
  return currentQuestion ? (
    <div className="question">
      <Debug text={`${currentQuestion.quarter}.${currentQuestion.period}.${currentQuestion.day}`}/>
      {currentQuestion.text}
    </div>
  ) : <div className="question"/>;
}

export default Question;
