export function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function wait(seconds: number) {
  return new Promise((accept) => {
    setTimeout(() => {
      accept();
    }, seconds * 1000);
  })
}
