import React, { useContext }               from 'react';
import { useRecoilState }                  from 'recoil';
import {
  currentQuestionState,
  currentSatisfactionState,
  currentValuationState,
  gameOverState,
  loadingState,
  periodState,
  quarterState,
  routeState,
  settingsDebugModeState,
  settingsMaxQuestionsPerPeriodState,
  settingsMinQuestionsPerPeriodState,
  volumeState
}                                          from '../atoms';
import { getSettings }                     from '../api';
import { getNextQuestions, initQuestions } from '../store';
import { Routes }                          from '../enums/Routes';
import { SoundItem }                       from '../enums/SoundItem';
import { SoundsContext }                   from '../SoundsContext';

function PlayButton() {
  const sounds = useContext(SoundsContext);
  const [volume] = useRecoilState(volumeState);
  const [settingsMinQuestionsPerPeriod, setSettingsMinQuestionsPerPeriod] = useRecoilState(settingsMinQuestionsPerPeriodState);
  const [settingsMaxQuestionsPerPeriod, setSettingsMaxQuestionsPerPeriod] = useRecoilState(settingsMaxQuestionsPerPeriodState);
  const [, setSettingsDebugMode] = useRecoilState(settingsDebugModeState);
  const [, setQuarter] = useRecoilState(quarterState);
  const [, setPeriod] = useRecoilState(periodState);
  const [, setQuestion] = useRecoilState(currentQuestionState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [, setRoute] = useRecoilState(routeState);
  const [gameOver] = useRecoilState(gameOverState);
  const [, setSatisfaction] = useRecoilState(currentSatisfactionState);
  const [, setValuation] = useRecoilState(currentValuationState);
  return (
    <div className="play-button" onClick={async () => {
      if (!loading) {
        sounds.play(SoundItem.Click, volume);
        const newQuarter = 0;
        const newPeriod = 1;
        setSatisfaction(4);
        setValuation(0);
        setQuarter(newQuarter);
        setPeriod(newPeriod);
        setLoading(true);
        const rawSettings = await getSettings();
        const minQuestionsPerPeriod = Number(rawSettings.min_questions_per_period) || settingsMinQuestionsPerPeriod;
        const maxQuestionsPerPeriod = Number(rawSettings.max_questions_per_period) || settingsMaxQuestionsPerPeriod;
        const debugMode = rawSettings.debug_mode === 'true';
        setSettingsDebugMode(debugMode);
        if (minQuestionsPerPeriod && maxQuestionsPerPeriod && minQuestionsPerPeriod <= maxQuestionsPerPeriod) {
          setSettingsMinQuestionsPerPeriod(minQuestionsPerPeriod);
          setSettingsMaxQuestionsPerPeriod(maxQuestionsPerPeriod);
        }
        await initQuestions(newQuarter, newPeriod);
        const res = await getNextQuestions({
          quarter: newQuarter,
          period: newPeriod,
          minQuestionsPerPeriod,
          maxQuestionsPerPeriod,
        });
        if (res) {
          setQuestion(res.question);
          setQuarter(res.quarter);
          setPeriod(res.period);
          setRoute(Routes.Default);
        }
        setLoading(false);
      }
    }}>
      {gameOver ? 'Խաղալ նորից' : 'Խաղալ'}
    </div>
  );
}

export default PlayButton;
