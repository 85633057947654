import React, { useContext }             from 'react';
import { ReactComponent as Logo }        from '../assets/logo.svg';
import { ReactComponent as StartupLogo } from '../assets/startup_logo.svg';
import { useRecoilState }                from 'recoil';
import { openInfoState, volumeState }    from '../atoms';
import { SoundItem }                     from '../enums/SoundItem';
import { SoundsContext }                 from '../SoundsContext';

function InfoModal() {
  const [infoModal, setInfoModal] = useRecoilState(openInfoState);
  const sounds = useContext(SoundsContext);
  const [volume] = useRecoilState(volumeState);

  return (
    <div className="info-modal game" style={{
      display: infoModal ? 'flex' : 'none',
    }}>
      <div className="info-modal-content">
        <div className="info-modal-logos">
          <Logo width="80%" height="80%"/>
          <StartupLogo width="80%" height="80%"/>
        </div>
        <div className="info-modal-text">
          <div className="info-modal-text-intro">
            Երբ համաճարակի պատճառով բոլորը փակված էին իրենց տներում, Ստարտափ Արմենիայի թիմը
            որոշեց անգործ չմնալ և ստեղծեց Էդուգեյմը։
          </div>
          <div className="info-modal-text-rules">
            <h3>Խաղի կանոնները</h3>
            <p>Քո կազմակերպությունը պետք է մինչև տարեվերջ գնահատվի առնվազն 1 մլրդ դոլար։</p>
            <p>Ողջամիտ տնօրինի՛ր ժամանակդ։</p>
            <p>Ու աշխատի՛ր աշխատակիցներիդ երջանիկ պահել։</p>
          </div>
        </div>
        <div className="info-modal-close" onClick={() => {
          sounds.play(SoundItem.Click, volume);
          setInfoModal(false);
        }}>
          Փակել
        </div>
      </div>
    </div>
  );
}

export default InfoModal;
