import React     from 'react';
import Screen    from '../components/Screen';
import Header    from '../components/Header';
import InfoModal from '../components/InfoModal';

function Mobile() {
  return (
    <div className="mobile">
      <InfoModal/>
      <Header/>
      <Screen/>
    </div>
  );
}

export default Mobile;
