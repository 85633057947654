import React, { useContext }          from 'react';
import { ReactComponent as InfoIcon } from '../assets/info.svg';
import BottomItem                     from './BottomItem';
import { useRecoilState }             from 'recoil';
import { openInfoState, volumeState } from '../atoms';
import { SoundItem }                  from '../enums/SoundItem';
import { SoundsContext }              from '../SoundsContext';

function Info() {
  const sounds = useContext(SoundsContext);
  const [volume] = useRecoilState(volumeState);
  const [, setInfoModal] = useRecoilState(openInfoState);
  return (
    <BottomItem onClick={() => {
      sounds.play(SoundItem.Click, volume);
      setInfoModal(true);
    }}>
      <InfoIcon height="100%" width="100%"/>
    </BottomItem>
  );
}

export default Info;
