import React         from 'react';
import Main          from './Main';
import BottomButtons from './BottomButtons';

function Screen() {
  return (
    <div className="screen">
      <Main/>
      <BottomButtons/>
    </div>
  );
}

export default Screen;
