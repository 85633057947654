import React, { useEffect }         from 'react';
import { useRecoilState }           from 'recoil';
import { loadingState, routeState } from './atoms';
import Debug                        from './components/Debug';
import Route                        from './components/Route';
import audio                        from './AudioService';
import { SoundsContext }            from './SoundsContext';
import { install }                  from 'ga-gtag';
import { getSetting }               from './api';

function App() {
  const [loading] = useRecoilState(loadingState);
  const [route] = useRecoilState(routeState);
  useEffect(() => {
    async function installGtag() {
      try {
        const item = await getSetting('gtag_id');
        if (item) {
          install(item.value);
        }
      } catch (e) {
        console.error('GTAG not loaded');
      }
    }

    installGtag();
  }, []);
  let className = 'app';
  if (loading) {
    className += ' progress'
  }
  return (
    <SoundsContext.Provider value={audio}>
      <div className={className}>
        <video autoPlay muted loop id="bg-video">
          <source src="video/background.mp4" type="video/mp4"/>
        </video>
        <Debug text={'DEBUG MODE'}/>
        <div className="game unselectable">
          <Route route={route}/>
        </div>
      </div>
    </SoundsContext.Provider>
  );
}

export default React.memo(App);
