import React, { useContext, useState }           from 'react';
import { useRecoilState }                        from 'recoil';
import {
  addedValuationState,
  currentQuestionState,
  currentSatisfactionState,
  currentValuationState,
  gameOverState,
  loadingState,
  periodState,
  quarterState,
  routeState,
  settingsMaxQuestionsPerPeriodState,
  settingsMinQuestionsPerPeriodState,
  volumeState
}                                                from '../atoms';
import { getNextQuestions, setHighScore, store } from '../store';
import Debug                                     from './Debug';
import { Routes }                                from '../enums/Routes';
import { GameOverReason }                        from '../enums/GameOverReason';
import { SoundItem }                             from '../enums/SoundItem';
import { SoundsContext }                         from '../SoundsContext';

function Answer({answer}: { answer: any }) {
  const [selected, setSelected] = useState() as any;
  const [loading, setLoading] = useRecoilState(loadingState);
  const {answers} = store;
  const [settingsMinQuestionsPerPeriod] = useRecoilState(settingsMinQuestionsPerPeriodState);
  const [settingsMaxQuestionsPerPeriod] = useRecoilState(settingsMaxQuestionsPerPeriodState);
  const [quarter, setQuarter] = useRecoilState(quarterState);
  const [period, setPeriod] = useRecoilState(periodState);
  const [, setQuestion] = useRecoilState(currentQuestionState);
  const [valuation, setValuation] = useRecoilState(currentValuationState);
  const [, setAddedValuation] = useRecoilState(addedValuationState);
  const [satisfaction, setSatisfaction] = useRecoilState(currentSatisfactionState);
  const [, setRoute] = useRecoilState(routeState);
  const [, setGameOver] = useRecoilState(gameOverState);
  const sounds = useContext(SoundsContext);
  const [volume] = useRecoilState(volumeState);

  async function onAnswer() {
    if (!loading) {
      sounds.play(SoundItem.Click, volume);
      setSelected(true);
      let newValuation = valuation + answer.revenue;
      setValuation(newValuation);
      setAddedValuation(answer.revenue);
      setTimeout(() => {
        setAddedValuation(0);
      }, 1000);
      let newSatisfaction = satisfaction + answer.satisfaction;
      if (newSatisfaction > 10) {
        newSatisfaction = 10;
      } else if (newSatisfaction < 0) {
        newSatisfaction = 0;
      }
      setSatisfaction(newSatisfaction);
      answers.push(answer);
      if (newValuation < -5) {
        setHighScore(newValuation);
        setGameOver(GameOverReason.LostNoValuation);
        setRoute(Routes.GameOver);
        sounds.play(SoundItem.Lost, volume);
        return;
      }
      if (newSatisfaction === 0) {
        setHighScore(newValuation);
        setGameOver(GameOverReason.LostNoSatisfaction);
        setRoute(Routes.GameOver);
        sounds.play(SoundItem.Lost, volume);
        return;
      }
      setLoading(true);
      const res = await getNextQuestions({
        quarter,
        period,
        minQuestionsPerPeriod: settingsMinQuestionsPerPeriod,
        maxQuestionsPerPeriod: settingsMaxQuestionsPerPeriod,
      });
      setLoading(false);
      if (res?.question) {
        if (quarter && quarter !== res.quarter) {
          sounds.play(SoundItem.Quarter, volume);
        }
        setQuestion(res.question);
        setQuarter(res.quarter);
        setPeriod(res.period);
      } else {
        if (newValuation > 1000) {
          setHighScore(newValuation);
          setGameOver(GameOverReason.Win);
          setRoute(Routes.GameOver);
          sounds.play(SoundItem.Win, volume);
        } else {
          setHighScore(newValuation);
          setGameOver(GameOverReason.LostNoQuestionsLeft);
          setRoute(Routes.GameOver);
          sounds.play(SoundItem.Lost, volume);
        }
      }
    }
  }

  let className = 'answer';
  if (loading) {
    className += ' progress'
  }
  return (
    <div className={className} onClick={onAnswer} aria-selected={selected}>
      {answer.text}
      <Debug text={`R:${answer.revenue}, S: ${answer.satisfaction}`}/>
    </div>
  );
}

export default Answer;
