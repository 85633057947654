import React, { useContext }          from 'react';
import { ReactComponent as SoundOn }  from '../assets/sound_on.svg';
import { ReactComponent as SoundOff } from '../assets/sound_off.svg';
import { ReactComponent as SoundMed } from '../assets/sound_med.svg';
import BottomItem                     from './BottomItem';
import { useRecoilState }             from 'recoil';
import { volumeState }                from '../atoms';
import { Volume }                     from '../enums/Volume';
import { SoundItem }                  from '../enums/SoundItem';
import { SoundsContext }              from '../SoundsContext';

function Sound() {
  const sounds = useContext(SoundsContext);
  const [volume, setVolume] = useRecoilState(volumeState);
  let VolumeIcon;
  switch (volume) {
    case Volume.On:
      VolumeIcon = SoundOn;
      sounds.unmute(SoundItem.Background);
      break;
    case Volume.OnlySounds:
      VolumeIcon = SoundMed;
      sounds.mute(SoundItem.Background);
      break;
    case Volume.Off:
      VolumeIcon = SoundOff;
      sounds.mute(SoundItem.Background);
      break;
  }

  return (
    <BottomItem onClick={() => {
      sounds.play(SoundItem.Click, volume);
      if (volume === Volume.Off) {
        setVolume(Volume.OnlySounds);
      } else if (volume === Volume.On) {
        setVolume(Volume.Off);
      } else if (volume === Volume.OnlySounds) {
        setVolume(Volume.On);
      }
    }}>
      <VolumeIcon height="100%" width="100%"/>
    </BottomItem>
  );
}

export default Sound;
