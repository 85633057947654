import React from 'react';

function BottomItem(props: any) {
  return (
    <div className="bottom-item" onClick={props.onClick}>
      {props.children}
    </div>
  );
}

export default BottomItem;
