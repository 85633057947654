import React                          from 'react';
import { useRecoilState }             from 'recoil';
import { quarterState }               from '../atoms';
import { ReactComponent as Quarter1 } from '../assets/battery_01.svg'
import { ReactComponent as Quarter2 } from '../assets/battery_02.svg'
import { ReactComponent as Quarter3 } from '../assets/battery_03.svg'
import { ReactComponent as Quarter4 } from '../assets/battery_04.svg'
import Debug                          from './Debug';
import HeaderItem                     from './HeaderItem';

function Quarter() {
  const [quarter] = useRecoilState(quarterState);
  let QuarterLogo: any;
  switch (quarter) {
    case 0:
    case 1:
      QuarterLogo = Quarter1;
      break;
    case 2:
      QuarterLogo = Quarter2;
      break;
    case 3:
      QuarterLogo = Quarter3;
      break;
    case 4:
      QuarterLogo = Quarter4;
      break;
  }
  return (
    <HeaderItem>
      <div className="quarter">
        <Debug text={`${quarter}`}/>
        {QuarterLogo ? <QuarterLogo height="100%" width="100%"/> : ''}
      </div>
    </HeaderItem>
  );
}

export default Quarter;
