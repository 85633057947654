import React                    from 'react';
import Answer                   from './Answer';
import { useRecoilState }       from 'recoil';
import { currentQuestionState } from '../atoms';

function Answers() {
  const [currentQuestion] = useRecoilState(currentQuestionState);
  return (
    <div className="answers">
      {currentQuestion?.answers.map((a: any) => {
        return <Answer key={`answer-${a.id}`} answer={a}/>
      })}
    </div>
  );
}

export default Answers;
