import { SoundItem } from './enums/SoundItem';
import { Volume }    from './enums/Volume';

class AudioService {
  [SoundItem.Background] = new Audio(`/sound/${SoundItem.Background}.mp3`);
  [SoundItem.Click] = new Audio(`/sound/${SoundItem.Click}.mp3`);
  [SoundItem.Quarter] = new Audio(`/sound/${SoundItem.Quarter}.mp3`);
  [SoundItem.Win] = new Audio(`/sound/${SoundItem.Win}.mp3`);
  [SoundItem.Lost] = new Audio(`/sound/${SoundItem.Lost}.mp3`);

  constructor() {
    this[SoundItem.Background].loop = true;
    this[SoundItem.Background].muted = false;
  }

  public mute(item: SoundItem) {
    const audio = this[item];
    if (!audio.muted) {
      audio.muted = true;
    }
  }

  public unmute(item: SoundItem) {
    const audio = this[item];
    if (audio.muted) {
      audio.muted = false;
    }
  }

  public async play(item: SoundItem, volume: Volume) {
    switch (item) {
      case SoundItem.Background:
        const bg = this[item];
        if (bg.paused) {
          try {
            await bg.play();
          } catch (e) {
            console.error('Playing', item, 'failed');
            console.error(e)
          }
        } else {
          bg.muted = volume !== Volume.On;
        }
        break;
      default:
        if (volume > Volume.Off) {
          await this[item].play();
        }
    }
  }
}

export default new AudioService();
