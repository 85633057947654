import React, { useContext }           from 'react';
import { Routes }                      from '../enums/Routes';
import { ReactComponent as Logo }      from '../assets/logo.svg';
import { ReactComponent as PoweredBy } from '../assets/powered_by.svg';
import PlayButton                      from '../components/PlayButton';
import ShareButton                     from '../components/ShareButton';
import HighScore                       from '../components/HighScore';
import Sound                           from '../components/Sound';
import { useRecoilState }              from 'recoil';
import { startedState, volumeState }   from '../atoms';
import { SoundItem }                   from '../enums/SoundItem';
import { SoundsContext }               from '../SoundsContext';

function Welcome() {
  const sounds = useContext(SoundsContext);
  const [volume] = useRecoilState(volumeState);
  const [started, setStarted] = useRecoilState(startedState);
  return (
    <div className={Routes.Welcome}>
      <div className="welcome-top">
        <Logo width="100%"/>
        <PoweredBy width="100%"/>
      </div>
      <div className="welcome-tutorial" style={{
        display: started ? 'none' : 'flex',
      }}>
        <div className="welcome-tutorial-text">
          <h3><b>Խաղի կանոնները</b></h3>
          <p>Քո կազմակերպությունը պետք է մինչև տարեվերջ գնահատվի առնվազն 1 մլրդ դոլար։</p>
          <p>Ողջամիտ տնօրինի՛ր ժամանակդ։</p>
          <p>Ու աշխատի՛ր աշխատակիցներիդ երջանիկ պահել։</p>
        </div>
        <div className="welcome-tutorial-ready" onClick={() => {
          setStarted(true);
          sounds.play(SoundItem.Background, volume);
          sounds.play(SoundItem.Click, volume)
        }}>
          Պատրաստ եմ
        </div>
      </div>
      {started ? (
        <div className="welcome-play">
          <HighScore/>
          <PlayButton/>
          <ShareButton/>
          <Sound/>
        </div>
      ) : ''}
    </div>
  );
}

export default Welcome;
