export async function getQuarterQuestionsByPeriod(quarter: number, period: number, options: { take?: number } = {}) {
  const res = await fetch(`/api/game/quarter/${quarter}/${period}?options=${JSON.stringify(options)}`);
  return res.json();
}

export async function getCharacter(id: number) {
  const res = await fetch(`/api/game/character/${id}`);
  return res.json();
}

export async function getSettings() {
  const res = await fetch(`/api/game/setting`);
  return res.json();
}

export async function getSetting(id: string) {
  const res = await fetch(`/api/game/setting/${id}`);
  if (res.status <=200) {
    return res.json();
  }
  throw res.text();
}
