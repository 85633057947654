import React, { Fragment }                      from 'react';
import { Routes }                               from '../enums/Routes';
import { ReactComponent as WinLogo }            from '../assets/logo_win.svg';
import { ReactComponent as LostLogo }           from '../assets/logo_lost.svg';
import { ReactComponent as Win }                from '../assets/win.svg';
import { ReactComponent as LostNoSatisfaction } from '../assets/lost_no_satisfaction.svg';
import { ReactComponent as LostNoValuation }    from '../assets/lost_no_valuation.svg';
import { ReactComponent as LostNoQuarter }      from '../assets/lost_no_quarter.svg';
import PlayButton                               from '../components/PlayButton';
import ShareButton                              from '../components/ShareButton';
import HighScore                                from '../components/HighScore';
import Sound                                    from '../components/Sound';
import { useRecoilState }                       from 'recoil';
import { gameOverState }                        from '../atoms';
import { GameOverReason }                       from '../enums/GameOverReason';

function GameOver() {
  const [gameOver] = useRecoilState(gameOverState);
  let Logo;
  let Text = <Fragment/>;
  let Icon: any = Fragment;
  switch (gameOver) {
    case GameOverReason.LostNoSatisfaction:
      Logo = LostLogo;
      Icon = LostNoSatisfaction;
      Text = <Fragment>Ոչինչ եթե ստարտափի մի թիմակից լքում է այն, <br/> բայց որ բոլո՞րը․․․</Fragment>;
      break;
    case GameOverReason.LostNoValuation:
      Logo = LostLogo;
      Icon = LostNoValuation;
      Text = <Fragment>Փողերդ քամո՞ւն տվեցիր։ <br/> Ոչի՜նչ, այդպես էլ է պատահում։ </Fragment>;
      break;
    case GameOverReason.LostNoQuestionsLeft:
      Logo = LostLogo;
      Icon = LostNoQuarter;
      Text = <Fragment>Այս տարի չկարողացաք յունիքորն դառնալ, <br/> բայց ամեն ինչ դեռ առջևում է։</Fragment>;
      break;
    case GameOverReason.Win:
      Logo = WinLogo;
      Icon = Win;
      Text = <Fragment>Բարի գալուստ յուննիքորների ընտանիք։ <br/> Դուք հաղթեցիք։</Fragment>;
      break;
  }
  return (
    <div className={Routes.GameOver}>
      <div className="game-over-header">
        {Logo ? <Logo width="70%" height="90%"/> : ''}
      </div>
      <div className="game-over-content">
        <div className="game-over-icon">
          {Icon ? <Icon width="80%"/> : ''}
        </div>
        <div className="game-over-text">
          {Text}
        </div>
        <div className="game-over-bottom">
          <HighScore/>
          <PlayButton/>
          <ShareButton/>
          <Sound/>
        </div>
      </div>
    </div>
  );
}

export default GameOver;
